<template>
  <div class="more-block">
    <!-- <div class="pc-list"> -->
    <tableList
      :dataList="blockList"
      :mDataList="mBlockList"
      :title="$t('table.newBlockData')"
      :columns="blockColumns"
      :mColumns="mBlockColumns"
      @more="toMoreBlock('/moreBlockData')"
      @reachBottom="load"
    >
      <template #prefix>
        <div class="title-prefix">
          <el-divider direction="vertical"></el-divider>
          <el-radio-group
            v-model="activeBlockType"
            size="small"
            @change="handleSizeChange"
          >
            <el-radio-button
              :label="key"
              v-for="(value, key) in blockType"
              :key="key"
              >{{ value }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </template>
      <!-- <template #suffix>
        <div class="total-span">
          共{{allAdCount}}条
        </div>
      </template> -->
      <template #hash="{ scoped }">
        <div
          class="blue text-overflow2"
          @click="toHashDetail(scoped.row.blockHash)"
        >
          {{ scoped.row.blockHash }}
        </div>
      </template>
      <template #pagination>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="getBlockData"
            :current-page.sync="pagination.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="pagination.size"
            layout="total, sizes, prev, pager, next"
            :total="pagination.total"
          >
            <!-- <span class="total-span mr-24">最近{{pagination.total}}条</span> -->
          </el-pagination>
        </div>
      </template>
    </tableList>
    <!-- </div> -->
    <!-- <div class="mobile-list" >
      <mobileTableList
        :dataList="mBlockList"
        :title="$t('table.newBlockData')"
        :columns="mBlockColumns"
        @reachBottom="load"
        >
        <template #prefix>
          <div class="title-prefix">
            <el-radio-group
              v-model="activeBlockType"
              size="small"
              @change="getBlockData"
            >
              <el-radio-button
                :label="key"
                v-for="(value, key) in blockType"
                :key="key"
                >{{ value }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </template>
         <template #hash="{scoped}">
          <div class="blue text-overflow2" @click="toHashDetail(scoped.row.blockHash)">
            {{ scoped.row.blockHash }}
          </div>
        </template></mobileTableList>
    </div> -->
  </div>
</template>

<script>
import tableList from '@/components/data/tableList.vue'
import tableConfig from '@/components/data/tableConfig'
// import mobileTableList from '@/components/data/mobileTableList'
import api from '@/api/services/browser'
export default {
  components: {
    tableList
    // mobileTableList
  },
  data() {
    return {
      blockList: [],
      mBlockList: [],
      blockColumns: tableConfig.blockColumns,
      mBlockColumns: tableConfig.mBlockColumns,
      blockType: {
        adf: 'ADFun区块',
        nft: 'NFT区块'
      },
      activeBlockType: 'adf',
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      mPagination: {
        page: 2,
        size: 10,
        total: 0,
        totalPage: 0
      },
      isPost: false
      // allAdCount: 0
    }
  },
  created() {
    const query = this.$route.query

    if (query.type) {
      this.activeBlockType = query.type
    }
    this.getBlockData()
  },
  methods: {
    handleSizeChange() {
      this.pagination.page = 1
      this.mPagination.page = 2
      this.getBlockData()
    },
    toHashDetail(hash) {
      this.$router.push(
        `/hashBlock?blockHash=${hash}&type=${this.activeBlockType}`
      )
    },
    load() {
      if (this.mPagination.page < this.mPagination.totalPage) {
        this.getMobileBlockData()
      }
    },
    async getMobileBlockData() {
      if (this.isPost) {
        return false
      }
      try {
        this.isPost = true
        const data = await api.getBlockList({
          type: this.activeBlockType,
          page: this.mPagination.page,
          size: this.mPagination.size
        })
        this.isPost = false
        this.mBlockList = this.mBlockList.concat(data.blockList || [])
        this.mPagination.page += 1
        this.mPagination.totalPage = Math.ceil(
          data.total / this.mPagination.size
        )
      } catch (e) {
        this.isPost = false
      }
      // this.allAdCount = data.allAdCount
    },
    async getBlockData() {
      const data = await api.getBlockList({
        type: this.activeBlockType,
        page: this.pagination.page,
        size: this.pagination.size
      })
      this.blockList = data.blockList || []
      this.pagination.total = data.total
      this.mPagination.totalPage = Math.ceil(data.total / this.mPagination.size)
      if (this.pagination.page === 1) {
        this.mBlockList = data.blockList || []
        this.mPagination.page = 2
      }
      // this.allAdCount = data.allAdCount
    }
  }
}
</script>

<style lang="less" scoped>
// .title-prefix {
//  ::v-deep {
//     .el-radio-button{
//       .el-radio-button__inner{
//         color: #999999;
//         background: #fff;
//          border: 1px solid #fff;
//       }
//       &.is-active{
//         .el-radio-button__inner{
//            background: #F2F8FF;
//            border-radius: 2px;
//            color: #408FFF;
//           border: 1px solid #A6CBFF;
//           box-shadow:none;
//         }
//       }
//     }
//   }
// }
.mobile-list {
  min-height: 100vh;
  overflow: auto;
}
</style>
